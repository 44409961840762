$(function () {

    let rtlValue = null;

    if ($("html").attr("dir") == "rtl") {
        rtlValue = true;
    } else {
        rtlValue == false;
    }
    // initialize with defaults
    $("#reviewRating").rating({
        showClear: false,
        showCaption: false,
        rtl: rtlValue,
        containerClass: "is-star",
        size: "sm",
        starTitles: false,
    });

    $("[name=rating]").rating({
        showClear: false,
        showCaption: false,
        rtl: rtlValue,
        containerClass: "is-star",
        size: "sm",
        displayOnly: true,
        starTitles: false,
    });

    $(".giveReviewRating").rating({
        showClear: false,
        showCaption: false,
        rtl: rtlValue,
        containerClass: "is-star",
        size: "xs",
        displayOnly: true,
        starTitles: false,
    });
});
